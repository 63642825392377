<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户信息：">
        <el-input v-model="nickname" placeholder="请输入用户昵称" size="small"></el-input>
      </el-form-item>
      <el-form-item label="日志日期：">
        <el-date-picker
          @change="getTime"
          v-model="time"
          size="small"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList()">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nickname" label="用户信息" align="center"></el-table-column>
      <el-table-column prop="tablename_str" label="日志类型" align="center"></el-table-column>
      <el-table-column prop="remark" label="操作" align="center"></el-table-column>
      <el-table-column prop="content" label="相关内容" align="center"></el-table-column>
      <el-table-column prop="comfrom" label="登录端口" align="center"></el-table-column>
      <el-table-column prop="create_time" label="日志时间" align="center"></el-table-column>
    </el-table>
    <Paging
      class="paging"
      :total="total_number"
      :page="page"
      :pageNum="rows"
      @updatePageNum="updateData"
    ></Paging>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue'
import { getDateformat } from '../../util/getDate'
export default {
  components: { Paging },
  data() {
    return {
      list: [],
      time: '',
      page: 1,
      rows: 10,
      nickname: '',
      total_number: 0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
        nickname: this.nickname,
        starttime: this.starttime,
        endtime: this.endtime
      }
      if (this.$route.query.user_id) data.user_id = this.$route.query.user_id
      this.$axios
        .post(this.$api.user.log, data)

        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list
            for (let i in list) {
              list[i].create_time = getDateformat(list[i].create_time)
            }
            console.log(list)
            this.list = list
            this.total_number = res.result.total
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    getTime(val) {
      this.starttime = val[0].getTime() / 1000
      this.endtime = val[0].getTime() / 1000
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val
        this.getList()
      } else {
        this.page = val
        this.getList()
      }
    },
    cancelSearch() {
      this.page = 1
      this.nickname = ''
      this.starttime = ''
      this.endtime = ''
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
